export const getPathWithoutSectionUrl = ({
  route,
  topology,
  shouldNavigateToFeed,
}) => {
  const { sectionUrl, postPageSectionUrl, baseUrl } = topology;
  const currentSectionUrl = shouldNavigateToFeed
    ? baseUrl === route
      ? baseUrl
      : sectionUrl
    : postPageSectionUrl;
  return route.startsWith(currentSectionUrl)
    ? route.slice(currentSectionUrl.length)
    : route;
};
